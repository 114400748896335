import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PatientService } from 'src/services/Patients';
import { getProgramTypesList } from 'src/services/Posts';
import ProgramService from 'src/services/Program/program.service';

function useFetchPrograms({ setProgramList }: { setProgramList?: (val: any[]) => void }) {
    return useQuery({
        queryKey: ['programs'],
        queryFn: async () => await ProgramService.getPrograms(),
        onSuccess: () => {},
        onError: () => {},
        select: (data) => {
            const programItems = data?.data?.data?.items;
            const sortedItems = programItems?.sort((a: any, b: any) => b.id - a.id);
            setProgramList?.(sortedItems);
            return programItems;
        },
    });
}

function useFetchPatientPrograms({ patientId }: { patientId: number }) {
    return useQuery({
        queryKey: [`patient-programs-${patientId}`],
        queryFn: async () => await PatientService.getProgramList(patientId),
        onSuccess: () => {},
        onError: () => {},
        select: (res) => {
            const patientProgramItems = res?.data?.data;
            return patientProgramItems;
        },
    });
}

function useFetchPreselctedPatientPrograms({ patientId }: { patientId: number }) {
    return useQuery({
        queryKey: [`patient-preselected-programs-${patientId}`],
        queryFn: async () => await PatientService.getPatientProgram({ patientId }),
        onSuccess: () => {},
        onError: () => {},
        select: (res) => {
            const programs = res?.data?.data;

            if (programs?.length) {
                const preSelectedProgram = programs?.find((program: any) => Boolean(program.preselected === 1));
                const preSelectedPatientProgram = {
                    id: +preSelectedProgram.programId,
                    name: preSelectedProgram.programTitle,
                    ...preSelectedProgram,
                };

                return preSelectedPatientProgram;
            }

            return {};
        },
    });
}

function useFetchPathologies() {
    return useQuery({
        queryKey: ['pathologies'],
        queryFn: async () => await getProgramTypesList(),
        onSuccess: () => {},
        onError: () => {},
        select: (data) => {
            const pahologies = data?.data?.data?.items;
            const sortedItems = pahologies?.sort((a: any, b: any) => b.id - a.id);
            return sortedItems ?? [];
        },
    });
}

function useFetchSurveyParent() {
    return useQuery({
        queryKey: ['survey-parent'],
        queryFn: async () => await ProgramService.GetSurveyParent(),
        onSuccess: () => {},
        onError: () => {},
        select: (res) => {
            const surveyParents = res?.data?.data;
            return surveyParents;
        },
    });
}

// PREFETCH
function usePreFetchProgramsRQHooks() {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery({
        queryKey: ['programs'],
        queryFn: async () => await ProgramService.getPrograms(),
    });
}

export {
    useFetchSurveyParent,
    useFetchPathologies,
    useFetchPrograms,
    usePreFetchProgramsRQHooks,
    useFetchPatientPrograms,
    useFetchPreselctedPatientPrograms,
};
