import { ButtonBase } from '@mui/material';
import { FC } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';
import { sxStyles } from './styles';
import IconCheckList from './../CustomIcon/IconCheckList';
import moment from 'moment';

interface ConclusionItemProps {
    type?: string;
    title?: string;
    program?: {
        id: number;
        name: string;
    };
    onPress?: Function;
    conclusion?: any;
}

export const ConclusionItem: FC<ConclusionItemProps> = (props) => {
    const { type, onPress, program, conclusion } = props;
    const creationDate = moment(conclusion?.createdAt).format('DD/MM/YYYY');

    return (
        <ButtonBase style={sxStyles.container} onClick={() => onPress?.()}>
            <div style={{ paddingLeft: 20, flex: 1 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CustomText
                        fontSize={baseFontSize.sm}
                        style={{ textAlign: 'left', marginRight: 10 }}
                        color={baseColors.white}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                    >
                        Synthèse du programme :
                    </CustomText>

                    {program?.id && (
                        <div
                            style={{
                                padding: '3px 15px',
                                backgroundColor: baseColors.white,
                                borderRadius: 5,
                                marginRight: 20,
                            }}
                        >
                            <CustomText
                                fontSize={baseFontSize.mini}
                                color={baseColors.orange.primary}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                            >
                                {program?.name}
                            </CustomText>
                        </div>
                    )}
                </div>

                <div style={{ marginTop: 10 }}>
                    <CustomText
                        fontSize={baseFontSize.sm}
                        style={{ textAlign: 'left', marginRight: 10 }}
                        color={baseColors.white}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                    >
                        Date : {creationDate ?? ''}
                    </CustomText>
                </div>
            </div>

            <div
                style={{
                    ...sxStyles.right,
                    paddingRight: type !== 'APA' ? 8 : 0,
                }}
            >
                <CustomText
                    style={{ marginRight: type !== 'APA' ? 50 : 58 }}
                    color={baseColors.white}
                    fontFamily={baseFontFamily.BreeSerif}
                >
                    {type}
                </CustomText>

                <div style={sxStyles.icon} />

                <IconCheckList height={35} width={35} />
            </div>
        </ButtonBase>
    );
};
